import React, { useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash";
import { ColDef } from "ag-grid-community";

import Grid from "../../components/Grid/Grid";
import { Transaction, getTransactions } from "../../services/exchange";
import { createNumberFormatter } from "../../utils/format";

function getColDefs(): ColDef[] {
  return [
    {
      field: "id",
      headerName: "Receipt",
      cellRenderer: ({ value }: { value: Transaction["id"] }) => {
        return (
          <a title={value} href={`/receipt/swap/${value}`} style={{ fontSize: "1em" }}>
            View
          </a>
        );
      },
      width: 110
    },
    {
      field: "tradeDate",
      headerName: "Trade Date",
      valueFormatter: ({ value }) => new Date(value).toLocaleString(),
      width: 180
    },

    {
      field: "baseAmount",
      headerName: "Base Amount",
      valueFormatter: (col) => {
        if (!col.value) {
          return "...";
        }

        const nbFormatter = createNumberFormatter("fr-FR");
        return nbFormatter(col.value);
      },
      cellStyle: {
        textAlign: "right",
        fontFamily: "monospace"
      }
    },
    {
      field: "baseCurrency",
      headerName: "Base Ccy",
      width: 120
    },
    {
      field: "quoteCurrency",
      headerName: "Quote Ccy",
      width: 120
    }
  ];
}

export function SwapTransactionsComponent() {
  const [txs, setTxs] = useState<Transaction[]>([]);

  const getTxs = useCallback(async () => {
    const _txs = await getTransactions();

    if (!isEqual(txs, _txs)) {
      setTxs(_txs);
    }
  }, [txs]);

  useEffect(() => {
    getTxs();
  });

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setTxs([]);
          getTxs();
        }}
      >
        Refresh
      </button>

      <Grid<Transaction> rowData={txs} columnDefs={getColDefs()} />
    </>
  );
}
