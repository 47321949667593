import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

import HeaderComponent from "../../components/Header/HeaderComponent";

import OnboardingType from "./OnboardingType";

import "./WelcomePage.css";
import { useAppContext } from "../../utils/contexts";

export default function WelcomePage() {
  const { user } = useUser();
  const { account } = useAppContext();
  const navigate = useNavigate();
  const [isBusiness, setIsBusiness] = useState<boolean | undefined>();

  useEffect(() => {
    if (account?.id) {
      navigate("/account");
    }
  }, [account, navigate]);

  return (
    <div className="main-layout">
      <HeaderComponent />
      <div className="page-content">
        <main>
          <h2>Welcome {user?.firstName}!</h2>
          <div className="welcome-page">
            <p className="intro">
              <b>Solide Finance</b> offers a new experience for <b>cross-border payments</b> and <b>multi-currency</b>{" "}
              treasury management.
              <br />
              Tell us more about you and we'll quickly set you up with an account!
            </p>
            <div>
              <div className="container">
                <form action="#">
                  <OnboardingType isBusiness={isBusiness} onChange={(val) => setIsBusiness(val)} />
                  <div className="button-bar">
                    <button
                      type="button"
                      onClick={() => navigate(isBusiness ? "/business-onboarding" : "/individual-onboarding")}
                    >
                      Start Onboarding
                    </button>
                  </div>
                </form>
              </div>
              <h4>Account Creation</h4>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
