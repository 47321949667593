import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useUser } from "@clerk/clerk-react";
import { DocumentKind, OnboardingKind } from "@prisma/client";

import HeaderComponent from "../../components/Header/HeaderComponent";
import SelectorComponent from "../../components/Selector/SelectorComponent";

import { onboardAccount } from "../../services/account";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { useAppContextDispatch } from "../../utils/contexts";

import "./OnboardingPage.css";

// Keep in sync with same validator in server/apis/account
// no shared files between the two apps.
const PreOnboardingSchema = Yup.object().shape({
  fullName: Yup.string().required("Required"),
  country: Yup.string().required("Required").min(2),
  monthlyTradingVolume: Yup.string().required("Required").min(20),
  accountingCcy: Yup.string().required("Required").min(3),
  contactEmail: Yup.string().email().required("Required"),
  documentKind: Yup.string().required("Required"),
  documentId: Yup.string().required("Required").min(3)
});

export default function IndividualOnboardingComponent() {
  const { user } = useUser();
  const dispatch = useAppContextDispatch();

  return (
    <div className="main-layout">
      <HeaderComponent />
      <div className="page-content">
        <main>
          <h2>Let's get to know you better.</h2>

          <div className="onboarding-page">
            <p className="intro">
              This is our registration form, tell us more about yourself so we can activate your account.
            </p>
            <div>
              <div className="container">
                <Formik
                  initialValues={{
                    fullName: "",
                    address: "",
                    city: "",
                    zipCode: "",
                    country: "France",
                    documentKind: null,
                    documentId: "",
                    monthlyTradingVolume: "less than 1 000 euros",
                    accountingCcy: "",
                    contactEmail: user?.emailAddresses[0].emailAddress || "",
                    contactPhone: "",
                    onboardingKind: OnboardingKind.INDIVIDUAL
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    const account = await onboardAccount(values);
                    dispatch({
                      property: "account",
                      value: account
                    });
                    setSubmitting(false);
                    window.location.assign("/account");
                  }}
                  validationSchema={PreOnboardingSchema}
                >
                  {({ values, isSubmitting, handleSubmit, handleChange, handleBlur, errors, touched, isValid }) => (
                    <Form onSubmit={handleSubmit} className="onboarding-component">
                      <fieldset>
                        <legend>About yourself</legend>
                        <div>
                          <label htmlFor="fullName">Full Name*</label>
                          <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            placeholder="e.g. Jane Doe"
                            value={values.fullName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {errors.fullName && touched.fullName ? (
                            <span className="error-field">* {errors.fullName}</span>
                          ) : null}
                        </div>
                        <div>
                          <label htmlFor="address">Address</label>
                          <input
                            type="text"
                            id="address"
                            name="address"
                            placeholder="Address"
                            value={values.address}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          <label htmlFor="city">City</label>
                          <input
                            type="text"
                            id="city"
                            name="city"
                            placeholder="City"
                            value={values.city}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          <label htmlFor="zipCode">Zip Code</label>
                          <input
                            type="text"
                            id="zipCode"
                            name="zipCode"
                            placeholder="Zip Code"
                            value={values.zipCode}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          <label htmlFor="country">Country*</label>
                          <input
                            type="text"
                            id="country"
                            name="country"
                            placeholder="France"
                            value={values.country}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {errors.country && touched.country ? (
                            <span className="error-field">* {errors.country}</span>
                          ) : null}
                        </div>
                      </fieldset>
                      <fieldset>
                        <legend>Proof of Identification</legend>
                        <div>
                          <SelectorComponent
                            label="Type of Document*"
                            title="Choose type of identification document"
                            htmlName="documentKind"
                            values={[
                              {
                                name: "Passport",
                                id: DocumentKind.PASSPORT
                              },
                              {
                                name: "Driving License",
                                id: DocumentKind.DRIVING_LICENSE
                              },
                              {
                                name: "National Id Card",
                                id: DocumentKind.NATIONAL_ID_CARD
                              }
                            ]}
                            onBlur={handleBlur}
                            selected={values.documentKind}
                            onSelect={handleChange}
                          />
                          {errors.documentKind && touched.documentKind ? (
                            <span className="error-field">* {errors.documentKind}</span>
                          ) : null}
                        </div>
                        <div>
                          <label htmlFor="documentId">Document Id*</label>
                          <input
                            type="text"
                            id="documentId"
                            name="documentId"
                            placeholder="documentId"
                            value={values.documentId}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {errors.documentId && touched.documentId ? (
                            <span className="error-field">* {errors.documentId}</span>
                          ) : null}
                        </div>
                      </fieldset>
                      <fieldset>
                        <legend>Expected usage</legend>
                        <div>
                          <SelectorComponent
                            label="Monthly trading volume (cross border payments and receivables in &euro;)*"
                            title="Expected monthly trading volume"
                            htmlName="monthlyTradingVolume"
                            values={[
                              {
                                name: "less than 1 000 euros",
                                id: "less than 1000 euros"
                              },
                              {
                                name: "1 000 euros to 10 000 euros",
                                id: "1 000 euros to 10 000 euros"
                              },
                              {
                                name: "10 000 euros and more",
                                id: "10 000 euros and more"
                              }
                            ]}
                            onBlur={handleBlur}
                            selected={values.monthlyTradingVolume}
                            onSelect={handleChange}
                          />
                          {errors.monthlyTradingVolume && touched.monthlyTradingVolume ? (
                            <span className="error-field">* {errors.monthlyTradingVolume}</span>
                          ) : null}
                        </div>
                        <div>
                          <SelectorComponent
                            label="Destination currency (the currency you most often convert to)*"
                            title="Which is your destination currency?"
                            htmlName="accountingCcy"
                            values={[
                              {
                                name: "USD",
                                id: "USD"
                              },
                              {
                                name: "EUR",
                                id: "EUR"
                              }
                            ]}
                            selected={values.accountingCcy}
                            onBlur={handleBlur}
                            onSelect={handleChange}
                          />
                          {errors.accountingCcy && touched.accountingCcy ? (
                            <span className="error-field">* {errors.accountingCcy}</span>
                          ) : null}
                        </div>
                      </fieldset>
                      <fieldset>
                        <legend>Contact</legend>
                        <div>
                          <label htmlFor="contactEmail">Email Address*</label>
                          <input
                            type="text"
                            id="contactEmail"
                            name="contactEmail"
                            placeholder="Email"
                            value={values.contactEmail}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {errors.contactEmail && touched.contactEmail ? (
                            <span className="error-field">* {errors.contactEmail}</span>
                          ) : null}
                        </div>
                        <div>
                          <label htmlFor="contactPhone">Phone number</label>
                          <input
                            type="text"
                            name="contactPhone"
                            id="contactPhone"
                            placeholder="Phone number"
                            value={values.contactPhone}
                            onChange={handleChange}
                          />
                        </div>
                      </fieldset>
                      <div>
                        <button type="submit" disabled={isSubmitting || !isValid}>
                          {isSubmitting ? (
                            <>
                              <LoadingIndicator />
                            </>
                          ) : (
                            "Create Account"
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <h4>Individual Registration</h4>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
