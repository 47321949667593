import axiosClient from "./axiosClient";

export interface Currency {
  id: string;
  name: string;
  symbol: string;
  address: string;
  isoCurrency: string;
  isTradeable: boolean;
  createdAt: string;
  updatedAt: string;
}

export async function getCurrencies() {
  const response = await axiosClient.get<Currency[]>("/currencies");

  return response.data;
}
