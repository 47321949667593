import React from "react";

import "./OnboardingType.css";

interface OnboardingTypeProps {
  isBusiness?: boolean;
  onChange: (isBusiness: boolean) => void;
}

export default function OnboardingType({ isBusiness, onChange }: OnboardingTypeProps) {
  const businessSelected = typeof isBusiness === "boolean" && isBusiness;
  const individualSelected = typeof isBusiness === "boolean" && !isBusiness;

  return (
    <ul className="onboarding-type">
      <li onClick={() => onChange(false)} className={(individualSelected && "selected") || ""}>
        <input type="radio" id="individual" name="type" value="individual" readOnly checked={individualSelected} />
        <div>
          <label htmlFor="individual">Individual</label>
          <p>If you're using SolideFinance for yourself.</p>
        </div>
      </li>
      <li onClick={() => onChange(true)} className={(businessSelected && "selected") || ""}>
        <input type="radio" id="business" name="type" value="business" readOnly checked={businessSelected} />
        <div>
          <label htmlFor="business">Business</label>
          <p>If you're using SolideFinance on behalf of your company.</p>
        </div>
      </li>
    </ul>
  );
}
