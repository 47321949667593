import React, { useEffect, useState } from "react";

import { createCurrencyFormatter } from "../../utils/format";
import { getPairMarketData } from "../../services/exchange";

import "./ViewLiquidityComponent.css";

interface ViewLiquidityComponentProps {
  ccy1: string;
  ccy2: string;
}

export default function ViewLiquidityComponent({ ccy1, ccy2 }: ViewLiquidityComponentProps) {
  const currencyFormatter1 = createCurrencyFormatter("fr-FR", ccy1);
  const currencyFormatter2 = createCurrencyFormatter("fr-FR", ccy2);

  const [marketData, setMarketData] = useState({
    ccy1: 0,
    ccy2: 0,
    fees: 0,
    pendingCcy1: 0,
    pendingCcy2: 0
  });

  useEffect(() => {
    async function loadMarketData() {
      setMarketData(await getPairMarketData({ ccy1, ccy2 }));
    }

    loadMarketData();
  }, [ccy1, ccy2]);

  return (
    <div className="view-liquidity-component">
      <h5>Market data</h5>
      <div className="explanation">
        Market data gives an indication of the maximum size of the trade which can be executed instantly. When trades
        get close to 80% of the shown liquidity, they are split into multiple trades (or fills) so the order is
        completed over time. The amount pending to be processed indicates that there are trades before you waiting to be
        executed, but don't worry, this should only take a few minutes.
      </div>
      <h6>Fees</h6>
      <dl>
        <div>
          <dt>
            Swap <b>fee</b>
          </dt>
          <dd>{marketData.fees}%</dd>
        </div>
      </dl>
      <h6>Pool Liquidity</h6>
      <dl>
        <div>
          <dt>
            <b>{ccy1}</b> liquidity
          </dt>
          <dd>{currencyFormatter1(marketData.ccy1)}</dd>
        </div>

        <div>
          <dt>
            <b>{ccy2}</b> liquidity
          </dt>
          <dd>{currencyFormatter2(marketData.ccy2)}</dd>
        </div>
      </dl>
      <h6>Pending transactions</h6>
      <dl>
        <div>
          <dt>
            <b>{ccy1}</b> Pending to be converted
          </dt>
          <dd>{currencyFormatter1(marketData.pendingCcy1)}</dd>
        </div>
        <div>
          <dt>
            <b>{ccy2}</b> Pending to be converted
          </dt>
          <dd>{currencyFormatter1(marketData.pendingCcy2)}</dd>
        </div>
      </dl>
    </div>
  );
}
