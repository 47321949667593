import React from "react";

import "./SelectorComponent.css";

interface SelectableValue {
  name: string;
  id: string;
}

interface SelectorComponentProps {
  title: string;
  label: string;
  htmlName: string;
  values: SelectableValue[];
  selected: string | null;
  onSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
}

export default function SelectorComponent({
  onSelect,
  onBlur,
  values,
  selected,
  label,
  htmlName,
  title
}: SelectorComponentProps) {
  return (
    <div className="selector-component">
      <label>{label}</label>
      <select value={selected || ""} name={htmlName} onChange={onSelect} onBlur={onBlur}>
        <option value="">{title}</option>
        {values.map((value) => (
          <option key={value.id} value={value.id}>
            {value.name}
          </option>
        ))}
      </select>
    </div>
  );
}
