import { PreOnboardingDetails } from "@prisma/client";
import axiosClient from "./axiosClient";

export interface Account {
  id: string;
  externalId: string;
  createdAt: string;
  smartAccountAddress: string;
  canTrade: boolean;
}

type PreOnboarding = Partial<Omit<PreOnboardingDetails, "id">>;

export async function getAccount(): Promise<Account> {
  const response = await axiosClient.get<Account>("/account");

  return response.data;
}

export async function hasAccount() {
  try {
    await getAccount();
    return true;
  } catch (e) {
    return false;
  }
}

export async function createAccount() {
  const response = await axiosClient.post<Account>("/account");

  return response.data;
}

export interface Balance {
  isoCurrency: string;
  balance: number;
}

export async function getBalances() {
  const response = await axiosClient.get<Balance[]>("/account/balances");

  return response.data;
}

export async function onboardAccount(data: PreOnboarding) {
  const response = await axiosClient.post<PreOnboarding>("/account/onboarding", data);

  return response.data;
}

export async function updatePreOnboardingAccountId(accountId: PreOnboarding["accountId"]) {
  const response = await axiosClient.put<PreOnboarding>("/account/onboarding/accountId", {
    accountId
  });

  return response.data;
}

export async function isCanTradeRequested() {
  const response = await axiosClient.get("/account/rights/status");

  const { type, date } = response.data.canTrade;

  if (type === "GRANT") {
    return {
      requested: true,
      date
    };
  } else {
    return {
      requested: false
    };
  }
}

export async function requestTraderAccess() {
  const response = await axiosClient.put("/account/rights/trader/grant");

  return response;
}
